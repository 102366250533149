<template>
  <div id="couponListPage">
    <!-- Header -->
    <div class="header">
      <el-row>
        <el-col :span="18"> Coupons </el-col>
        <el-col :span="6" style="text-align: right">
          <a target="_blank" href="http://help.growlytics.in/en/articles/5400692-how-to-generate-and-send-coupon-codes-in-growlytics-shopify-only">
            <el-button style="float: right" type="text"> <i class="el-icon-notebook-2"></i> How coupon works? </el-button>
          </a>
        </el-col>
      </el-row>

      <el-row class="filters">
        <el-col :span="18">
          <el-input class="searchCampaignInput" prefix-icon="el-icon-search" size="small" v-model="searchFilter" @input="onSearch" placeholder="Search Coupon"> </el-input>
        </el-col>
        <el-col :span="6" style="text-align: right">
          <router-link :to="{ path: '/coupon/create' }">
            <el-button size="small" type="primary" :loading="creatingCoupon" @click="onCreateCouponClick"> <i class="el-icon-circle-plus-outline el-icon-right"></i> &nbsp; Create Coupon </el-button>
          </router-link>
        </el-col>
      </el-row>
    </div>

    <el-alert type="warning" :closable="false" class="integrationWarning">
      <template v-if="$store.state.token.companyPlatformType == 'shopify'"> If you are using shopify, you will need to re-install the app in order to create coupon on your store. Please contact support for further details. </template>
      <template v-else> You will need to integrate <a target="_blank" href="https://docs.growlytics.in/webhooks/coupon-webhook">coupon webhooks</a> to sync coupons being created for customers.</template>
    </el-alert>

    <!-- Loader -->
    <div class="loadingDiv" v-if="fetchingData && couponList" v-loading="true" style="min-height: 300px"></div>

    <!-- Campaign List -->
    <div class="couponList" v-if="!fetchingData && couponList && couponList.length > 0">
      <!-- Header Row -->
      <div class="couponItem headerRow">
        <!-- General Details -->
        <div class="details" style="padding-left: 12px">Coupon Name</div>

        <!-- Created At -->
        <div class="startTime">Last Updated</div>
      </div>

      <!-- Coupon List -->
      <div class="couponItem" v-for="(coupon, index) in couponList" :key="index">
        <!-- General Details -->
        <div class="details">
          <div>
            <router-link class="detailsLink" :to="getCouponDetailsPageLink(coupon.id)">
              {{ coupon.name }}
            </router-link>
          </div>
          <div class="metaInfo">
            {{ coupon.description }}
          </div>
        </div>

        <!-- Created At -->
        <div class="startTime">
          <div>{{ coupon.updated_at }}</div>
          <div v-if="coupon.updator_name">By {{ coupon.updator_name }}</div>
        </div>
      </div>

      <!-- Bottom Pagination -->
      <div class="pagination pagination-bottom">
        <div class="totalCount">Total {{ pageOptions.total }} Coupons</div>
        <el-pagination @current-change="fetchCouponList" :current-page.sync="pageOptions.pageNumber" :page-size="pageOptions.pageSize" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
      </div>
    </div>

    <!-- No Data -->
    <div class="noDataDiv" v-if="!fetchingData && couponList && couponList.length == 0">
      <div class="msg">No Coupons Found</div>
      <router-link :to="{ path: '/coupon/create' }">
        <el-button class="createCouponButton" :loading="creatingCoupon" type="primary" @click="onCreateCouponClick"> Create New Coupon </el-button>
      </router-link>

      <div class="couponFeature" style="width: 90%">
        Target you custoemrs with discount coupons based on their behaviour.
        <br />
        Use automations to generate and deliver coupons on different messaging channels.

        <el-row :gutter="30" class="featureList">
          <el-col :span="8">
            <i class="fa fa-tags"></i>
            <div class="title">Unique Coupons</div>
            <div class="description">Generate uniuqe codes for different customers based on their past behaviour and automation flow.</div>
          </el-col>
          <el-col :span="8">
            <i class="fa fa-magic" aria-hidden="true"></i>
            <div class="title">Configurable Coupons</div>
            <div class="description">Use coupons based on rules like free shipping or discount percentage with. Rule coupons with conditions like minimum order value or specific products.</div>
          </el-col>
          <el-col :span="8">
            <i class="fa fa-line-chart" aria-hidden="true"></i>
            <div class="title">Understand What Drives Sales</div>
            <div class="description">Measure coupon impact of coupons. See of orders and sales increased with given coupon codes.</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="./couponList.scss"></style>

<script>
import couponListComponent from './couponListComponent';
export default couponListComponent;
</script>
