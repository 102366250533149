import { fetchCouponList } from '../../services/coupon';
import companyModule from '../../services/company';

const moment = require('moment');
const _ = require('lodash');

export default {
  name: 'couponList',
  props: {
    channelType: String,
    channel: String
  },
  data() {
    return {
      creatingCoupon: false,
      fetchingData: false,
      brandInfo: null,
      couponList: [],
      couponReportPageRoute: '',

      searchFilter: '',
      statusFilter: [],
      filterOptions: {
        status: [
          { label: 'Draft', value: 'draft' },
          { label: 'Running', value: 'running' },
          { label: 'Suspended', value: 'suspended' }
        ]
      },

      pageOptions: {
        pageSize: 10,
        pageNumber: 1,
        total: null
      }
    };
  },

  methods: {
    onCreateCouponClick() {
      this.creatingCoupon = true;
    },

    getCouponDetailsPageLink(couponId) {
      return (this.couponReportPageRoute = '/coupon/' + couponId);
    },

    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch();
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.pageNumber = 1;
      this.fetchCouponList();
    },

    fetchCouponList(isPageLoad) {
      this.fetchingData = true;

      // Wait for brand info
      if (!this.brandInfo) {
        setTimeout(() => {
          this.fetchCouponList(isPageLoad);
        }, 500);
        return;
      }

      let params = {
        search: this.searchFilter,
        statusFilter: this.statusFilter.length > 0 ? this.statusFilter : undefined,
        pageOptions: this.pageOptions
      };

      // Modify Query-String
      if (!isPageLoad) {
        const query = Object.assign({}, this.$route.query);
        query.search = params.search;
        query.statusFilter = JSON.stringify(params.statusFilter);
        query.pageOptions = JSON.stringify(params.pageOptions);
        this.$router.push({ query });
      }

      fetchCouponList(params, this)
        .then((result) => {
          this.couponList = result.data.list;
          this.pageOptions.total = result.data.total;

          for (var i = 0; i < this.couponList.length; i++) {
            this.couponList[i].created_at = this.couponList[i].created_at && this.couponList[i].created_at != 'N/A' ? this.getDisplayTime(this.couponList[i].created_at) : 'N/A';
            this.couponList[i].updated_at = this.couponList[i].updated_at && this.couponList[i].updated_at != 'N/A' ? this.getDisplayTime(this.couponList[i].updated_at) : 'N/A';

            // Creator
            this.creatorName = null;
            if (this.couponList[i].creator_first_name) {
              this.creatorName = this.couponList[i].creator_first_name + ' ' + this.couponList[i].creator_last_name;
            }
            this.couponList[i].creator_name = this.creatorName;

            // Updator
            this.updatorName = null;
            if (this.couponList[i].updator_first_name) {
              this.updatorName = this.couponList[i].updator_first_name + ' ' + this.couponList[i].updator_last_name;
            }
            this.couponList[i].updator_name = this.updatorName;

            // Build Description
            let coupon = this.couponList[i];
            let description = `${this.brandInfo.currency}${coupon.value} ${coupon.rule == 'percentage' ? '%' : ''} OFF`;
            description += `, Expires after ${coupon.expiry_value} ${coupon.expiry_unit}${coupon.expiry_value > 1 ? 's' : ''} `;

            if (coupon.rule == 'freeShipping') {
              description = 'Discount On shipping charges - ' + description;
            } else {
              if (coupon.applicable_on == 'order') {
                description = 'Discount on entire order - ' + description;
              } else if (coupon.applicable_on == 'product') {
                description = 'Discount on specifict products - ' + description;
              } else if (coupon.applicable_on == 'collection') {
                description = 'Discount on specifict collections - ' + description;
              }
            }

            this.couponList[i].description = description;
          }

          this.fetchingData = false;
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast('Something went wrong.');
          this.fetchingData = false;
        });
    },

    async readBrandInfo() {
      let brandInfo = await companyModule.getCompanyMetadata();
      this.brandInfo = brandInfo.data;
    },

    getDisplayTime(date) {
      if (!date) {
        return `N/A`;
      }
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY hh:mm:ss A');
    }
  },

  mounted() {
    // Read Brand Info
    this.readBrandInfo();

    // Modify Query-String
    const query = this.$route.query;
    this.searchFilter = query.search ? query.search : this.searchFilter;
    this.statusFilter = query.statusFilter ? JSON.parse(query.statusFilter) : this.statusFilter;
    this.pageOptions = query.pageOptions ? JSON.parse(query.pageOptions) : this.pageOptions;

    this.fetchCouponList(true);
  }
};
